import React, {useState} from 'react'
import {Button, Input} from 'react-daisyui'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'
import {useForm} from 'react-hook-form'
import {signUp} from 'aws-amplify/auth'
import {usePostOTAReservationMutation} from '../../services/guestPortalApi'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'

export const AddOTAReservationForm = ({
  onSignUpSuccess
}: {
  onSignUpSuccess?: Function
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch
  } = useForm()
  const [isSearching, setisSearching] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [
    postOTAReservation,
    postOTAReservationResult
  ] = usePostOTAReservationMutation()

  const handleSignUp = async (data: any) => {
    event.preventDefault()
    setisSearching(true)

    try {
      postOTAReservation(data).then(result => {
        if (onSignUpSuccess) {
          if ('data' in result) {
            onSignUpSuccess(result.data)
          } else {
            setErrorMessage('Unable to find reservation')
          }
        } else {
          setErrorMessage('Unable to find reservation')
        }
      })
      postOTAReservationResult.reset()
      console.log('postOTAReservationResult', postOTAReservationResult)
    } catch (error) {
      console.log('error adding ota res', error)
      setErrorMessage('Unable to add reservation')
    } finally {
      setisSearching(false)
    }
  }

  return (
    <div>
      <div className="font-semibold text-lg text-center">
        Let's look up your third party reservation.
      </div>
      {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
      {postOTAReservationResult.isLoading ? (
        <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
      ) : (
        ''
      )}
      {postOTAReservationResult.error ? '' : ''}
      {postOTAReservationResult.data ? (
        <div>
          <FontAwesomeIcon icon={faCheckCircle} />
          {JSON.stringify(postOTAReservationResult.data)}
          Success!
          <Button
            color="secondary"
            className="w-full mt-8"
            onClick={() => {}}
          />
        </div>
      ) : (
        ''
      )}

      {!postOTAReservationResult.isLoading &&
      !postOTAReservationResult.error &&
      !postOTAReservationResult.data ? (
        <form onSubmit={handleSubmit(handleSignUp)}>
          <div>
            <label className="label pb-1">OTA</label>
            <select
              {...register('ota', {required: true})}
              className="select select-bordered w-full"
              name="ota"
            >
              <option disabled selected>
                Select one
              </option>
              <option value={'Airbnb'}>Airbnb</option>
              <option value={'Booking'}>Booking.com</option>
              <option value={'Expedia'}>Expedia</option>
            </select>
            {errors.ota?.type === 'required' && (
              <p className="text-red-500" role="alert">
                OTA is required
              </p>
            )}
          </div>

          <div>
            <label
              className={
                'label pb-1' +
                (errors.confirmationCode ? ' text-red-error' : '')
              }
            >
              Confirmation code:
            </label>
            <Input
              {...register('confirmationCode', {
                required: true
              })}
              className={
                'input-form' +
                (errors.confirmationCode ? ' input-form-error' : '')
              }
              type="text"
              name="confirmationCode"
            />
            {errors.confirmationCode?.type === 'required' && (
              <p className="text-red-500" role="alert">
                Confirmation code is required
              </p>
            )}
          </div>

          <div>
            <label
              className={
                'label pb-1' + (errors.lastName ? ' text-red-error' : '')
              }
            >
              Last name on reservation:
            </label>
            <Input
              {...register('lastName', {
                required: true,
                pattern: /^[A-Za-z]+$/i
              })}
              className={
                'input-form' + (errors.lastName ? ' input-form-error' : '')
              }
              type="text"
              name="lastName"
            />
            {errors.lastName?.type === 'required' && (
              <p className="text-red-500" role="alert">
                Last name is required
              </p>
            )}
            {errors.lastName?.type === 'pattern' && (
              <p className="text-red-500" role="alert">
                Invalid last name
              </p>
            )}
          </div>
          <Button color="secondary" className="w-full mt-8" type="submit">
            import reservation information
          </Button>
          <div className={clsx({hidden: !isSearching})}>
            <LoadingSpinner size={LoadingSpinnerSize.Small} />
          </div>
        </form>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddOTAReservationForm
