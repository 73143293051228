import React, {useEffect} from 'react'
import {useAppSelector} from '../../store/hooks'
import {selectIsAuthenticated, selectIsMember} from '../../store/userSlice'
import {getLogger} from '../../services/logging'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {selectIsGuest} from '../../store/userSlice'
import LoadingScreen from '../common/LoadingScreen'

const logger = getLogger('AuthenticatedPage')

const AuthenticatedPage = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isGuest = useAppSelector(selectIsGuest)
  const isMember = useAppSelector(selectIsMember)

  useEffect(() => {
    logger.info({
      message: 'Checking for authenticated user...'
    })

    // redirect unauthenticated users to login
    if (!isAuthenticated) {
      logger.info({
        message: 'User is not authenticated.  Redirecting to login...'
      })
      navigate('/login', {state: {path: pathname}})
    }

    // member route redirects
    if (isMember) {
      if (pathname === '/') {
        logger.info({
          message: 'Redirecting member to default member route'
        })
        navigate('/member/stays')
      }

      if (!pathname.startsWith('/member')) {
        logger.info({
          message: `Invalid member path: ${pathname}.  Redirecting member to default member route`
        })
        navigate('/member')
      }
    }

    // guest route redirects
    if (isGuest) {
      if (pathname === '/') {
        logger.info({
          message: 'Redirecting user to default guest route'
        })
        navigate('/guest')
      }

      if (!pathname.startsWith('/guest')) {
        logger.info({
          message: `Invalid guest path: ${pathname}.  Redirecting guest to default guest route`
        })
        navigate('/guest')
      }
    }
  }, [isAuthenticated, isGuest, isMember, pathname])

  if (isAuthenticated) {
    return (
      <>
        <Outlet />
      </>
    )
  } else {
    return <LoadingScreen message="Redirecting to login..." />
  }
}

export default AuthenticatedPage
