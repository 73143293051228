import React from 'react'
import {Button, Modal} from 'react-daisyui'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClose, faEye} from '@fortawesome/free-solid-svg-icons'

interface ManageReservationProps {
  // reservation: Reservation
  reservation: {
    cancellationPolicy: string
  }
}

const CancellationPolicy = ({reservation}: ManageReservationProps) => {
  const {Dialog, handleShow} = Modal.useDialog()

  return (
    <div className="">
      <Dialog>
        <Modal.Actions>
          <form method="dialog relative">
            <div className="absolute top-0 right-0">
              <button className="btn-close">
                <FontAwesomeIcon
                  icon={faClose}
                  color="cabana"
                  className="text-xs text-center"
                />
              </button>
            </div>
          </form>
        </Modal.Actions>
        <Modal.Header className="font-bold text-2xl mb-4">
          Cancellation Policy
        </Modal.Header>
        <Modal.Body>{reservation.cancellationPolicy}</Modal.Body>
        {/* <Modal.Actions>
          <form method="dialog">
            <Button>Close</Button>
          </form>
        </Modal.Actions> */}
      </Dialog>
      <div className="pt-4 cursor-pointer" onClick={handleShow}>
        <a className="text-midnight">
          <FontAwesomeIcon
            icon={faEye}
            // color="cabana"
            className="text-xs pr-2 text-cabana"
          />
          View cancellation policy
        </a>
      </div>
    </div>
  )
}

export default CancellationPolicy
