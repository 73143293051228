import React, {useState} from 'react'
import {Button, Modal} from 'react-daisyui'
import {useCancelReservationMutation} from '../../services/guestPortalApi'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'

interface CancelReservationFormProps {
  reservation: any
  onCancel: () => void
  onGoBack?: () => void
}

const CancelReservationForm: React.FC<CancelReservationFormProps> = ({
  reservation,
  onCancel,
  onGoBack
}) => {
  const [
    cancelReservation,
    cancelReservationResult
  ] = useCancelReservationMutation()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  return (
    <div>
      <div className="font-bold text-2xl mb-4">Confirm Cancelllation</div>

      {cancelReservationResult.isLoading ? (
        <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
      ) : (
        <div>
          Are you sure you want to delete this reservation? This action cannot
          be undone, and the reservation details will be pemanently removed. If
          you need help or have any questions, please reach out to our team.{' '}
        </div>
      )}
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      <div>
        {/* <form method="dialog" className="p-4"> */}
        <div className="flex justify-end">
          <Button
            className="btn-md btn-outline btn-secondary mx-2"
            onClick={() => {
              if (onGoBack) {
                onGoBack()
              }
            }}
          >
            go back
          </Button>
          {!cancelReservationResult.error && (
            <Button
              className="btn btn-md btn-secondary"
              // type="submit"
              onClick={() => {
                cancelReservation({
                  reservationId: reservation.reservationId,
                  dealId: reservation.dealId
                })
                  .unwrap()
                  .then(() => {
                    if (onCancel) {
                      onCancel()
                    }
                  })
                  .catch(() => {
                    setErrorMessage('Unable to cancel reservation')
                  })
              }}
            >
              cancel reservation
            </Button>
          )}
        </div>
        {/* </form> */}
      </div>
    </div>
  )
}

export default CancelReservationForm
