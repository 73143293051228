import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export function formatDate(d: Date): string {
  try {
    return format(d, 'MMM d, yyyy')
  } catch (e) {
    console.warn('Failed to format date', d)
    return ''
  }
}

export function formatDateString(s: string): string {
  try {
    return formatDate(parseISO(s))
  } catch (e) {
    console.warn('Failed to format date string', s)
    return ''
  }
}

export const formatEndDate = (dateString: string) => {
  return formatDateWithOptions(dateString, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

export const formatStartDate = (dateString: string) => {
  return formatDateWithOptions(dateString, {month: 'short', day: 'numeric'})
}

export function formatDateWithOptions(
  dateString: string,
  options: Intl.DateTimeFormatOptions
): string {
  try {
    const date = new Date(dateString)
    date.setFullYear(2025)
    return date.toLocaleDateString('en-US', options)
  } catch (e) {
    console.warn('Failed to format date with options', dateString)
    return ''
  }
}
