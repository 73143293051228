import React, {useState} from 'react'
import {Button, Input} from 'react-daisyui'
import {
  useSendUserAttributeVerificationCodeMutation,
  useVerifyUserAttributeMutation
} from '../../services/cognitoApi'
import {SendUserAttributeVerificationCodeInput} from 'aws-amplify/auth'

interface VerifyUserAttributeProps {
  attribute: string
  attributeName: string
  attributeValue: any
}

export const VerifyUserAttribute = ({
  attribute,
  attributeName,
  attributeValue
}: VerifyUserAttributeProps) => {
  const [codeSent, setCodeSent] = useState(false)

  const [
    sendUserAttributeVerificationCode,
    sendUserAttributeVerificationCodeResult
  ] = useSendUserAttributeVerificationCodeMutation()

  const [
    verifyUserAttribute,
    verifyUserAttributeResult
  ] = useVerifyUserAttributeMutation()

  const handleSendCode = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendUserAttributeVerificationCode(attribute)
    setCodeSent(true)
  }

  return (
    <div className="bg-white">
      <h1 className="text-xl font-semibold">Verify {attributeName}</h1>
      {codeSent ? (
        <div>
          <p>
            Code sent to:{' '}
            {sendUserAttributeVerificationCodeResult.data.destination}
          </p>
          <form
            className="form-control "
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault()
              console.log(e)
              const target = e.target as HTMLFormElement
              verifyUserAttribute({
                userAttributeKey: attribute,
                confirmationCode: (target.elements as any).verificationCode
                  .value
              })
            }}
          >
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="label pt-6 pb-1">
                  Enter Verification Code
                </label>
                <Input name="verificationCode" className="input-form"></Input>
              </div>
              <button type="submit" className="btn btn-secondary">
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <form onSubmit={handleSendCode}>
          <label className="label pt-6 pb-1">
            <span className="label-text">{attributeName}</span>
          </label>
          <Input
            value={attributeValue}
            disabled
            required
            className="input-form"
          ></Input>
          <Button
            className="mt-6 btn-md text-lg font-semibold lowercase w-full"
            type="submit"
            color="secondary"
          >
            Send Code
          </Button>
        </form>
      )}
      <form method="dialog">
        <Button className="btn btn-link no-underline text-sm w-full">
          cancel
        </Button>
      </form>
    </div>
  )
}

export default VerifyUserAttribute
