import React from 'react'
import {getLogger} from '../../services/logging'
import ResetPasswordCode from '../account/ResetPasswordCode'
import {useNavigate} from 'react-router-dom'

const logger = getLogger('MemberLoginForm')

const MemberLoginForm = () => {
  const navigate = useNavigate()
  const onResetSuccess = () => {
    navigate('/login')
  }

  return (
    <>
      <ResetPasswordCode />
    </>
  )
}

export default MemberLoginForm
