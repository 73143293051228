import React, {useState} from 'react'
import {useUpdatePasswordMutation} from '../../services/cognitoApi'
import {Button, Input} from 'react-daisyui'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'

export const ResetPassword = ({onSuccess}: {onSuccess?: Function}) => {
  const [
    updatePasswordMutation,
    updatePasswordMutationResult
  ] = useUpdatePasswordMutation()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrorMessage(null)
    const target = e.target as HTMLFormElement
    if (
      (target.elements as any).newPassword.value !==
      (target.elements as any).confirmPassword.value
    ) {
      setErrorMessage('Passwords do not match!')
      return
    }
    updatePasswordMutation({
      oldPassword: (target.elements as any).oldPassword.value,
      newPassword: (target.elements as any).newPassword.value
    })
      .unwrap()
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(error => {
        return
      })
  }

  return (
    <div className="grid gap-2">
      <h1 className="font-semibold text-xl">Reset Password</h1>
      {errorMessage ? <div className="text-red-500">{errorMessage}</div> : ''}
      {updatePasswordMutationResult.isUninitialized ? (
        <form className="form-control " onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-2">
            <div>
              <label className="label pb-1 text-sm font-light">
                Current Password
              </label>
              <div className="relative">
                <Input
                  name="oldPassword"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your current password"
                  className="border-1 border-cabana bg-white w-full"
                ></Input>
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FontAwesomeIcon className="text-gray-400" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="text-gray-400"
                      icon={faEyeSlash}
                    />
                  )}
                </span>
              </div>
            </div>

            <div>
              <label className="label pb-1 text-sm font-light">
                New Password
              </label>
              <div className="relative">
                <Input
                  name="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your new password"
                  className="border-1 border-cabana bg-white w-full"
                  onChange={e => {
                    const confirmPasswordInput = (e.target.form as any)
                      .confirmPassword
                    if (
                      confirmPasswordInput.value &&
                      confirmPasswordInput.value !== e.target.value
                    ) {
                      setErrorMessage('Passwords do not match!')
                    } else {
                      setErrorMessage(null)
                    }
                  }}
                ></Input>
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FontAwesomeIcon className="text-gray-400" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="text-gray-400"
                      icon={faEyeSlash}
                    />
                  )}
                </span>
              </div>
            </div>
            <div>
              <label className="label pb-1 text-sm font-light">
                Confirm Password
              </label>
              <Input
                name="confirmPassword"
                type="password"
                placeholder="Confirm your new password"
                className="border-1 border-cabana bg-white w-full"
                onChange={e => {
                  const newPasswordInput = (e.target.form as any).newPassword
                  if (
                    e.target.value &&
                    e.target.value !== newPasswordInput.value
                  ) {
                    setErrorMessage('Passwords do not match!')
                  } else {
                    setErrorMessage(null)
                  }
                }}
              ></Input>
            </div>
            {/* <div className="text-red-500">Passwords do not match!</div> */}
            <Button
              className="mt-6 btn-md text-lg font-semibold lowercase w-full"
              color="secondary"
              type="submit"
            >
              Reset Password
            </Button>
          </div>
        </form>
      ) : (
        ''
      )}

      {updatePasswordMutationResult.error && (
        <div>
          <div className="text-red-500">
            {updatePasswordMutationResult.error.toString()}
          </div>
          <Button
            className="btn btn-link no-underline text-sm w-full"
            onClick={() => {
              updatePasswordMutationResult.reset()
              setErrorMessage(null)
            }}
          >
            try again
          </Button>
        </div>
      )}
      {updatePasswordMutationResult.isLoading && (
        <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
      )}
      {updatePasswordMutationResult.isSuccess && <div>Password Updated</div>}
    </div>
  )
}

export default ResetPassword
