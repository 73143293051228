import React, {useState} from 'react'
import {fetchAuthSession, signIn, signOut} from 'aws-amplify/auth'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import {getLogger} from '../../services/logging'
import {Input} from 'react-daisyui'

export interface MemberLoginFormProps {
  onLoginSuccess: (token: string) => void
  onLoginFailure: () => void
}

interface MemberLoginFormState {
  email: string
  password: string
  isAuthenticating?: boolean
}

const logger = getLogger('MemberLoginForm')

const MemberLoginForm = ({
  onLoginSuccess,
  onLoginFailure
}: MemberLoginFormProps) => {
  const [loginState, setLoginState] = useState<MemberLoginFormState>({
    email: '',
    password: '',
    isAuthenticating: false
  })

  const isValid = loginState.email?.length && loginState.password?.length

  return (
    <>
      <form className="flex flex-col gap-4 mb-8 w-full">
        <Input
          type="text"
          placeholder="Email"
          className="input-form border"
          value={loginState.email}
          onChange={e => {
            const email = e.currentTarget.value.trim()
            setLoginState({...loginState, email})
          }}
        />
        <Input
          type="password"
          placeholder="Password"
          className="input-form border"
          value={loginState.password}
          onChange={e => {
            const password = e.currentTarget.value.trim()
            setLoginState({...loginState, password})
          }}
        />
      </form>

      <button
        className="btn btn-secondary flex flex-row justify-center items-center w-full gap-2 mt-6"
        disabled={!isValid || loginState.isAuthenticating}
        onClick={async () => {
          setLoginState({
            ...loginState,
            isAuthenticating: true
          })

          const {email, password} = loginState
          try {
            await signOut()
            const response = await signIn({username: email, password})
            const {accessToken, idToken} =
              (await fetchAuthSession()).tokens ?? {}
            logger.info({
              message: 'Member login success',
              data: {response}
            })
            // const token = response.signInUserSession?.idToken?.jwtToken
            console.log('SIGN IN RESPONSE,', response, accessToken, idToken)
            onLoginSuccess(idToken.toString())
          } catch (error) {
            logger.error({
              message: 'Member login error',
              errorData: {error},
              data: {email}
            })

            setLoginState({
              ...loginState,
              isAuthenticating: false
            })

            onLoginFailure()
          }
        }}
      >
        <div className={clsx({hidden: !loginState.isAuthenticating})}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
        <div className="text-center">
          <FontAwesomeIcon icon={faRightToBracket} className="mr-3" />
          {loginState.isAuthenticating ? 'logging in...' : 'log in'}
        </div>
      </button>
    </>
  )
}

export default MemberLoginForm
