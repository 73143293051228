// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .tab:is(input:checked) {
  border-bottom: 3px solid var(--cabana);
} */

.tab:is(input[type='radio']) {
  display: none;
}

.tab[input] {
  display: none;
}

.tab:checked ~ .tab-label-1 {
  border-bottom: 3px solid var(--cabana);
  background-color: #fafafa;
}

.tab-input-2:checked ~ label {
  border-bottom: 3px solid var(--cabana);
  background-color: #fafafa;
}

.tab-label {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}


`, "",{"version":3,"sources":["webpack://./src/styles/tabs.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sCAAsC;EACtC,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;EACtC,yBAAyB;AAC3B;;AAGE;EAAA,kBAAgB;EAAhB,mBAAgB;EAAhB,iBAAgB;EAAhB;AAAgB","sourcesContent":["/* .tab:is(input:checked) {\n  border-bottom: 3px solid var(--cabana);\n} */\n\n.tab:is(input[type='radio']) {\n  display: none;\n}\n\n.tab[input] {\n  display: none;\n}\n\n.tab:checked ~ .tab-label-1 {\n  border-bottom: 3px solid var(--cabana);\n  background-color: #fafafa;\n}\n\n.tab-input-2:checked ~ label {\n  border-bottom: 3px solid var(--cabana);\n  background-color: #fafafa;\n}\n\n.tab-label {\n  @apply px-16 py-4\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
