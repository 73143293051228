import React from 'react'
import {Routes, Route} from 'react-router-dom'
import AuthenticatedPage from './routes/AuthenticatedPage'
import CheckinWelcomePage from './routes/CheckinWelcomePage'
import GuestReservationInfoPage from './routes/GuestReservationInfoPage'
import GuestPage from './routes/GuestPage'
import GuestVerificationPage from './routes/GuestVerificationPage'
import LoginPage from './routes/LoginPage'
import LogoutPage from './routes/LogoutPage'
import NotFoundPage from './routes/NotFoundPage'
import GuestCancellationPage from './routes/GuestCancellationPage'
import GuestPaymentPage from './routes/GuestPaymentPage'
import MemberPage from './routes/MemberPage'
import MemberDashboardPage from './routes/MemberDashboardPage'
import MemberProfilePage from './routes/MemberProfilePage'
import MemberReservationsPage from './routes/MemberReservationsPage'
import MemberRatesPage from './routes/MemberRatesPage'
import {defaultTheme} from 'react-daisyui/dist/constants'
import MemberCreateAccount from './auth/MemberCreateAccount'
import MemberForgotPassword from './auth/MemberForgotPassword'
import {ConfirmSignUpCodeForm} from './account/ConfirmSignUpCodeFirm'

const AppRoutes = () => {
  return (
    <Routes>
      {/* Login Routes */}
      <Route path="login" element={<LoginPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="forgot-password" element={<MemberForgotPassword />} />
      <Route path="signup" element={<MemberCreateAccount />} />
      <Route path="confirm" element={<LoginPage />} />

      {/* Landing Routes */}
      <Route path="checkin" element={<CheckinWelcomePage />} />

      {/* Authenticated Routes */}
      <Route path="/" element={<AuthenticatedPage />}>
        {/* Guest Portal Routes */}
        {/* <Route path="guest" element={<GuestPage />}>
          <Route index element={<GuestReservationInfoPage />} />
          <Route path="cancellation" element={<GuestCancellationPage />} />
          <Route path="payment" element={<GuestPaymentPage />} />
          <Route path="verification" element={<GuestVerificationPage />} />
        </Route> */}

        {/* Member Portal Routes */}
        <Route path="member" element={<MemberPage />}>
          <Route index element={<MemberDashboardPage defaultTab="stays" />} />
          <Route
            path="stays"
            element={<MemberDashboardPage defaultTab="stays" />}
          >
            {/* <Route
              path="stays\:reservationId"
              element={<MemberDashboardPage defaultTab="stays" />}
            /> */}
          </Route>
          <Route
            path="stays/:reservationId"
            element={<MemberDashboardPage defaultTab="stays" />}
          />
          <Route
            path="profile"
            element={<MemberDashboardPage defaultTab="profile" />}
          />
          {/* <Route path="reservations" element={<MemberReservationsPage />} />
          <Route path="rates" element={<MemberRatesPage />} /> */}
        </Route>
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
