import React, {useEffect, useState} from 'react'
import {
  useGetContactInfoQuery,
  usePostContactNotificationPreferencesMutation
} from '../../services/guestPortalApi'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'

export const NotificationPreferences = () => {
  const contactInfoQuery = useGetContactInfoQuery({})

  const [
    postContactInfoMutation,
    postContactInfoResult
  ] = usePostContactNotificationPreferencesMutation()

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const smsOptOut: string = e.target.value
    postContactInfoMutation({
      smsOptOut
    })
    setSmsOptOut(smsOptOut)
  }

  const handleMarketingEmailChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const marketingEmailUnsubscribed: boolean = !e.target.checked
    console.log('marketingEmailUnsubscribed', marketingEmailUnsubscribed, e)
    postContactInfoMutation({
      marketingEmailUnsubscribed
    })
    setMarketingEmailUnsubscribe(marketingEmailUnsubscribed)
  }

  const [smsOptOut, setSmsOptOut] = useState(null)
  const [marketingEmailUnsubscribe, setMarketingEmailUnsubscribe] = useState(
    null
  )

  useEffect(() => {
    if (contactInfoQuery.data) {
      setSmsOptOut(contactInfoQuery.data.smsOptOut)
      setMarketingEmailUnsubscribe(
        !contactInfoQuery.data.marketingEmailUnsubscribe
      )
    }
  }, [contactInfoQuery.data])

  return (
    <div className="grid grid-flow-row gap-6">
      <div className="grid gap-2">
        <h1 className="text-xl font-semibold">Notification Preferences</h1>
        <p className="text-sm font-light">
          Let us know how you want to communicate with us.
        </p>
      </div>
      {contactInfoQuery.data ? (
        <div className="grid grid-flow-row gap-4">
          <div className="grid gap-2">
            <h2 className="text-lg font-medium">Reservation Information</h2>
            <p className="text-sm font-light">
              Receive updates about your reservation, property updates, and
              more.
            </p>
            <div className="max-w-sm pt-2">
              <div className="form-control">
                <label className="label cursor-pointer justify-start">
                  <input
                    type="checkbox"
                    name="sms_opt_out"
                    value="both"
                    className="checkbox checkbox-sm checkbox-secondary checked:bg-cabana"
                    checked={smsOptOut === 'both'}
                    onChange={handleRadioChange}
                  />
                  <span className="label-text pl-2">
                    Text/SMS and Email updates
                  </span>
                </label>
              </div>
              <div className="form-control ">
                <label className="label cursor-pointer justify-start">
                  <input
                    type="checkbox"
                    name="sms_opt_out"
                    value="email_only"
                    className="checkbox checkbox-sm checkbox-secondary checked:bg-cabana"
                    checked={smsOptOut === 'email_only'}
                    onChange={handleRadioChange}
                  />
                  <span className="label-text pl-2">Email updates only</span>
                </label>
              </div>
            </div>
            <div>
              <p className="text-sm font-light italic pt-1 max-w-md">
                *Placemakr uses text/SMS to communicate important reservation
                details such as unit and access information.
              </p>
            </div>
          </div>
          <div className="grid grid-flow-row gap-2">
            <h2 className="text-lg font-medium">Information from Placemakr</h2>
            <p className="text-sm font-light">
              Stay up to date on deals, promotions, travel tips, and more.
            </p>
            <div className="form-control">
              <label className="cursor-pointer justify-start items-center align-middle">
                <input
                  type="checkbox"
                  checked={!marketingEmailUnsubscribe}
                  className="checkbox checkbox-sm checkbox-secondary align-middle"
                  onChange={handleMarketingEmailChange}
                />
                <span className="label-text pl-2 align-middle">
                  Email updates
                </span>
              </label>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner size={LoadingSpinnerSize.Small} />
      )}
    </div>
  )
}

export default NotificationPreferences
