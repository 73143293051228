import React, {useState} from 'react'
import {CognitoUserPool, CognitoUserAttribute} from 'amazon-cognito-identity-js'
import {
  autoSignIn,
  confirmSignUp,
  ConfirmSignUpInput,
  fetchAuthSession,
  signIn,
  signUp
} from 'aws-amplify/auth'
import {Button, Input} from 'react-daisyui'
import {getAwsConfig} from '../../services/config'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import CreateMemberForm from './CreateMemberForm'
import {setUser} from '../../store/userSlice'
import {ConfirmSignUpCodeForm} from './ConfirmSignUpCodeFirm'
import {sign, Sign} from 'crypto'
import {useNavigate} from 'react-router-dom'

const AwsConfig = getAwsConfig()
const poolData = {
  UserPoolId: AwsConfig.Auth.Cognito.userPoolId, // Your user pool id here
  ClientId: AwsConfig.Auth.Cognito.userPoolClientId // Your client id here
}

const userPool = new CognitoUserPool(poolData)

interface SignUpParameters {
  username: string
  password: string
  email: string
  phone_number: string
  given_name: string
  family_name: string
}

enum SignUpStep {
  SIGN_UP,
  CONFIRM_SIGN_UP,
  COMPLETE_AUTO_SIGN_IN
}

interface CreateMemberAccountProps {
  onLoginSuccess: (token: string) => void
  onLoginFailure: (error: Error) => void
  confirming?: boolean
}

const CreateMemberAccount: React.FC<CreateMemberAccountProps> = ({
  onLoginSuccess,
  onLoginFailure,
  confirming = false
}) => {
  const navigate = useNavigate()
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSigningUp, setIsSigningUp] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    phone_number: '',
    given_name: '',
    family_name: '',
    password: ''
  })

  const [verificationCode, setVerificationCode] = useState('')

  const startStep = confirming ? SignUpStep.CONFIRM_SIGN_UP : SignUpStep.SIGN_UP

  const [signUpStep, setSignUpStep] = useState(startStep)

  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setIsSigningUp(true)
    try {
      const {nextStep} = await signUp({
        username: formData.email,
        password: formData.password,
        options: {
          userAttributes: {
            given_name: formData.given_name,
            phone_number: formData.phone_number,
            family_name: formData.family_name
          },
          // optional
          autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        }
      })
      setSignUpStep(
        nextStep?.signUpStep === 'CONFIRM_SIGN_UP'
          ? SignUpStep.CONFIRM_SIGN_UP
          : SignUpStep.SIGN_UP
      )
    } catch (error) {
      console.log('error signing up', error)
      setError(error.message)
    } finally {
      setIsSigningUp(false)
    }
  }

  async function handleAutoSignIn() {
    try {
      const response = await autoSignIn()
      const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {}

      // const token = response.signInUserSession?.idToken?.jwtToken
      console.log('SIGN IN RESPONSE,', response, accessToken, idToken)
      onLoginSuccess(idToken.toString())
      // handle sign-in steps
    } catch (error) {
      console.log(error)
      navigate('/login')
    }
  }

  const handleConfirmSignUp = async (event: React.FormEvent) => {
    event.preventDefault()
    setIsConfirming(true)
    try {
      const {isSignUpComplete, nextStep} = await confirmSignUp({
        username: formData.email,
        confirmationCode: verificationCode
      })
      if (nextStep?.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
        setSignUpStep(SignUpStep.COMPLETE_AUTO_SIGN_IN)
        setIsAuthenticating(true)
        handleAutoSignIn()
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setIsConfirming(false)
    }
  }

  switch (signUpStep) {
    case SignUpStep.SIGN_UP:
      return (
        <CreateMemberForm
          onSignUpSuccess={({nextStep, data}: any) => {
            setFormData(data)
            setSignUpStep(
              nextStep?.signUpStep === 'CONFIRM_SIGN_UP'
                ? SignUpStep.CONFIRM_SIGN_UP
                : SignUpStep.SIGN_UP
            )
          }}
        />
      )
    case SignUpStep.CONFIRM_SIGN_UP:
      return (
        <ConfirmSignUpCodeForm
          username={formData.email}
          onConfirmed={({nextStep, data}: any) => {
            handleAutoSignIn()
            setIsAuthenticating(true)
            setSignUpStep(
              nextStep?.signUpStep === 'COMPLETE_AUTO_SIGN_IN'
                ? SignUpStep.COMPLETE_AUTO_SIGN_IN
                : SignUpStep.CONFIRM_SIGN_UP
            )
          }}
        />
        // <form onSubmit={handleConfirmSignUp} method="post">
        //   <div>
        //     <label className="label pt-6 pb-1">Enter Verification Code</label>
        //     <Input
        //       name="verificationCode"
        //       onChange={e => setVerificationCode(e.target.value)}
        //       className="input-form"
        //     ></Input>
        //   </div>
        //   <Button color="secondary" className="w-full mt-8" type="submit">
        //     confirm
        //   </Button>
        //   <div className={clsx({hidden: !isConfirming})}>
        //     <LoadingSpinner size={LoadingSpinnerSize.Small} />
        //   </div>
        // </form>
      )
    case SignUpStep.COMPLETE_AUTO_SIGN_IN:
      return (
        <div>
          <div className={clsx({hidden: !isAuthenticating})}>
            <LoadingSpinner size={LoadingSpinnerSize.Small} />
          </div>
          <div className="text-center">
            <FontAwesomeIcon icon={faRightToBracket} className="mr-3" />
            {isAuthenticating ? 'logging in...' : 'log in'}
          </div>
        </div>
      )
  }
}

export default CreateMemberAccount
